.nav-bar-block {
    height: min(48px, 6.575vw);
    display: block;
}

#nav-bar-row {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: min(48px, 6.575vw);
    margin-top: min(33px, 4.7vw);
    margin-bottom: -81px;
}

#nav-logo {
    z-index: 1000
}

#nav-logo > img {
    width: min(95px, 13vw);
    height: auto;
}

#desktop-menu {
    display: flex;
    z-index: 1000;
}

#desktop-menu > ul {
    display: flex;
    list-style: none;
    z-index: 1000;
}

#desktop-menu > ul > li {
    list-style-type: none;
    padding: 0 1.2em;
}

#desktop-menu > ul > li > a,
#desktop-menu > ul > li > div {
    display: inline;
    text-decoration: none;
    cursor: pointer;
}

#desktop-menu > ul > li > a > p,
#desktop-menu > ul > li > div > p {
    text-decoration: none;
    color: #373737;
    font-size: 1em;
    font-weight: 500;
}

#desktop-menu > ul > li > a > button,
#desktop-menu > ul > li > div > button {
    background: #7273f1;
    box-shadow: 0 2px 10px rgba(39, 0, 127, 0.4);
    border-radius: 10px;
    border: none;
    font-family: Metropolis, sans-serif;
    font-weight: 500;
    line-height: 33px;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: -2px;
}

#desktop-menu > ul > li > a:hover,
#desktop-menu > ul > li > div:hover {
    opacity: 0.8;
}

#mobile-menu-input {
    display: none;
}

#mobile-menu {
    width: min(44px, 6vw);
    height: min(44px, 6vw);
    display: none;
    background: linear-gradient(
            to bottom,
            #7273f1, #7273f1 20%,
            rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 0) 40%,
            #7273f1 40%, #7273f1 60%,
            rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0) 80%,
            #7273f1 80%, #7273f1 100%
    );
    z-index: 1000;
    margin-bottom: 4px;
    cursor: pointer;
}

#mobile-menu > nav {
    visibility: hidden;
    position: fixed;
    top: 0;
    right: -83vw;
    width: 80vw;
    height: 100%;
    background-color: #474554;
    transition: 0.3s;
    overflow-y: auto;
}

#mobile-menu > nav > #mobile-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
    height: 3.75rem;
    padding: calc(1rem * 0.5) 1rem;
}

#mobile-menu > nav > #mobile-menu-header > #mobile-menu-logo {
    display: block;
    height: 2rem;
}

#mobile-menu > nav > #mobile-menu-header > #mobile-menu-logo > img {
    height: 100%;
}

#mobile-menu > nav > #mobile-menu-header > div {
    display: flex;
    align-items: center;
}

#mobile-menu > nav > #mobile-menu-header > div > button {
    all: unset;
    cursor: pointer;
}

#mobile-menu > nav > #mobile-menu-header button {
    background: #7273f1;
    box-shadow: 0 2px 10px rgba(39, 0, 127, 0.4);
    border-radius: 10px;
    border: none;
    font-family: Metropolis, sans-serif;
    font-weight: 500;
    line-height: 33px;
    color: #ffffff;
    cursor: pointer;
    text-decoration: none;
    padding-left: 10px;
    padding-right: 10px;
    margin-right: 10px;
}

#mobile-menu > nav > ul {
    margin-top: 0.25rem;
    padding: 0.5rem;
}

#mobile-menu #mobile-menu-content li {
    list-style-type: none;
    margin-top: 0.25rem;
    padding: 0;
}

#mobile-menu > nav > ul > li:nth-child(1) {
    margin-top: 0 !important;
}

#mobile-menu #mobile-menu-content .mobile-menu-chevron {
    display: flex;
    justify-content: space-between;
}

#mobile-menu #mobile-menu-content .mobile-menu-item {
    display: block;
}

#mobile-menu #mobile-menu-content .mobile-menu-item,
#mobile-menu #mobile-menu-content .mobile-menu-chevron {
    cursor: pointer;
    width: calc(100% - 1rem);
    border-radius: 0.25rem;
    padding: 0.375rem 0.75rem;
}

#mobile-menu #mobile-menu-content .mobile-menu-item a,
#mobile-menu #mobile-menu-content .mobile-menu-item div,
#mobile-menu #mobile-menu-content .mobile-menu-chevron a,
#mobile-menu #mobile-menu-content .mobile-menu-chevron div {
    display: block;
    width: 100%;
    color: #dadde1;
    font-size: 1em;
    font-weight: 500;
    text-decoration: none;
    line-height: 1.25;
}

#mobile-menu #mobile-menu-content .mobile-menu-item:hover,
#mobile-menu #mobile-menu-content .mobile-menu-chevron:hover {
    /*background-color: #5e5b7b; !*selected colour*!*/
    background-color: #353341; /*hover colour*/
}

#mobile-menu #mobile-menu-content .mobile-menu-collapsible {
    overflow: hidden;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    margin-top: 0;
}

#mobile-menu-input:checked + #mobile-menu {
    cursor: auto;
}

#mobile-menu-input:checked + #mobile-menu > nav {
    visibility: visible;
    right: 0;
}

#mobile-menu-input:checked + #mobile-menu > #mobile-menu-overlay {
    visibility: visible;
    opacity: 1;
}

#mobile-menu-overlay {
    visibility: hidden;
    display: block;
    transition: opacity 0.2s ease;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 0;
}

@media (max-width: 996px) {
    #desktop-menu {
        display: none;
    }

    #mobile-menu {
        display: inline;
    }
}

@media (max-width: 700px) {
    #footer-row > div > p {
        display: none;
    }
}
